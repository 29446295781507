@import "./fonts";

$font-family-sans-serif: 'Open Sans',
sans-serif;
$font-family-sans-serif-bold: 'Open Sans bold',
sans-serif;
$font-family-sans-serif-light: 'Open Sans Light',
sans-serif;
$font-family-open-sans-condensed:'Open Sans Condensed',
sans-serif;
$font-family-abel: 'Abel',
sans-serif;
$font-family-roboto: 'roboto',
sans-serif;

$font-size-base: 1rem;
$font-size-lg: $font-size-base * 1.25;
$font-size-sm: $font-size-base * .875;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;

// $font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

//$font-family-base: $font-family-sans-serif;
$font-family-base: $font-family-roboto;

$display-font-family: $font-family-base;

$display-font-family-bold: $font-family-sans-serif-bold