*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  line-height: 1.15; // 3
  -webkit-text-size-adjust: 100%; // 4
  -webkit-tap-highlight-color: rgba($black, 0); // 5
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}


body {
  margin: 0; // 1
  font-family: $display-font-family;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $body-color;
  text-align: left; // 3
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: $body-bg; // 2
  // background-image: url("https://kfciadmin-staging.cognizantorderserv.com/Content/Images/bg.jpg");

}
.link-button{
  background:none;
  border:none;
  font-size: $button-font-size;
}
.btn.primary{
    background-color: $submit-button-background;
}
label{
  font-family: $display-font-family;
}

.disabled {
  opacity: 0.4!important;
  cursor: not-allowed;
}