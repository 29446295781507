@import "../../assets/scss/common.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
// @import "../../assets/scss/placeholders/placeholder";

.header-nav {
  width: 100%;
  position: fixed !important;
  height: $header-navbar-height;
  z-index: $header-navbar-z-index;
  justify-content: center !important;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, .15);
  @include glassmorphismBackground(10rem);
  background: rgba(255, 255, 255, 0.6);

  a {
    @include link-element;
  }

  .brandLink {
    font-size: $font-size-base;

  }

  svg {
    @include svg-vertical-align(baseline)
  }

  .card {
    border: 0;
  }

  .card-header {
    &:first-child {
      border-radius: none !important;
    }

    font-size: .8rem;
    text-transform: capitalize;
  }

  .dropdown {
    z-index: $header-user-icon-z-index;

    a {
      z-index: inherit;
    }
  }

  .navbar-collapse-btn {
    z-index: $sidebar-CollapseButton-z-index;
    top: 1.2rem;
    padding: 0;
    background: transparent;

    .navbar-collapse {
      div * {
        color: $black;
      }

      ul {
        &.accordion {
          margin-bottom: 0;
          font-size: $mobile-accordion-font-size;

          .card-header {
            a {
              font-size: inherit;
            }
          }

          .card-body {
            background: $white;
            @include padding(1rem .5rem);

            li {
              background: $white;
              @include padding(.5rem .8rem);
              @include border-bottom(1px, solid, $color-grey-light-3);

              a {
                color: $color-grey-dark-medium;
                background: $white;
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }
  }

  .logo {
    width: $header-logo-width;
  }

  .navbar-light .navbar-toggler {
    border: none;
    margin-bottom: .9rem;

    &:focus {
      outline: none;
    }
  }

  .header-user-info {
    position: absolute;
    right: 0rem;
  }
}

@include media-breakpoint-down(sm) {
  .header-nav {
    justify-content: start !important;
    @include glassmorphismBackground(3rem);

    .header-user-info {
      position: absolute;
      margin-right: 0 !important;
      margin: auto !important;
    }

    .navbar-collapse-btn {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
    }

    nav {
      left: 0;
    }
    .logo-wrapper{
      position: absolute;
      right:15px;
    }
  }

  .header-user-info {
    left: 0;
  }
}

@-moz-document url-prefix() {
  .header-nav {
    background: $white;
  }
}