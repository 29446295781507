@import "../placeholders/placeholder";

.table-layout {
    table-layout: fixed;

    .table th,
    .table td {
        padding: $table-th-td-padding;
    }
}

.rdt {

    &_Table {
        // @include custom-border(1px, solid, $color-table-border-grey);
        border-spacing: $dataTable-borderSpacing;
        border-collapse: collapse;
        margin-bottom: 4rem;

    }

    &_TableCol {
        &:not(:last-child) {
            // border-right: 1px solid $color-table-border-grey;
        }
    }

    &_TableCell {
        width: 100%;
        padding: 1rem 0rem;

        & div:first-child {
            white-space: normal!important;
        }

        &:not(:last-child) {
            // border-right: 1px solid $color-table-border-grey;
        }
    }

    &_Pagination {
        @include remove-border();
        @extend %display-flex;
        @extend %flex-row;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    &_TableHeader {
        display: none !important;
    }



    &_TableRow {
        &:last-child {
            // border-bottom: 1px solid $color-table-border-grey;
        }
    }

    &_TableBody {

        .badge {
            font-size: 88%;
        }
    }
}

header {
    &.judJDM {

        min-height: 0 !important;
    }
}

.payment-content {
    flex-wrap: wrap !important;
}


@include media-breakpoint-down(sm) {
    .card-body {
        padding: 1rem !important;
    }
}