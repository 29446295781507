@mixin word-wrap() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

@mixin border-radius($values...) {
  @each $var in $values {
    border-radius: #{$var};
  }
}

@mixin padding($values...) {
  @each $var in $values {
    padding: #{$var};
  }
}

@mixin margin($values...) {
  @each $var in $values {
    margin: #{$var};
  }
}

//SVG ALIGNMENT MIXINS

@mixin svg-vertical-align($value:middle) {
  vertical-align: $value;
}

//LINK MIXINS

@mixin link-element() {
  text-decoration: none !important;
  cursor: pointer;
}


// FONT MIXIN
@mixin custom-font-size($size) {
  font-size: $font-size-base + $size;
}

// CUSTOM BORDER MIXIN

@mixin custom-border($size:1px, $type:solid, $color:$color-primary) {
  border: none;
  border: $size $type $color;
}

@mixin border-bottom($size:1px, $type:solid, $color:$color-primary) {
  border-bottom: $size $type $color;
}

@mixin remove-border() {
  border: none;
}

@mixin remove-border-radius() {
  border-radius: 0 !important;
}

@mixin setBorderBox() {
  box-sizing: border-box;
}

//SHADOW MIXINS

@mixin basic-box-shadow() {
  box-shadow: 1px 1px 1px #ccc;
  -webkit-box-shadow: 1px 1px 1px #ccc;
  -moz-box-shadow: 1px 1px 1px #ccc;
}

@mixin inset-box-shadow($h-offset, $v-offset, $blur, $color) {
  -moz-box-shadow: inset $h-offset $v-offset $blur $color !important;
  -webkit-box-shadow: inset $h-offset $v-offset $blur $color !important;
  box-shadow: inset $h-offset $v-offset $blur $color !important;
}

@mixin custom-box-shadow($h-offset, $v-offset, $blur, $color) {
  box-shadow: $h-offset $v-offset $blur $color;
  -webkit-box-shadow: $h-offset $v-offset $blur $color;
  -moz-box-shadow: $h-offset $v-offset $blur $color;
}

@mixin cards-shadow() {
  box-shadow:0px 2px 5px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.2);
}

@mixin Form-Container-Shadow() {
  -webkit-box-shadow: -1px 17px 42px -18px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: -1px 17px 42px -18px rgba(0, 0, 0, 0.5);
  box-shadow: -1px 17px 42px -18px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
}


//BACKGROUND POSITION CALCULATOR

@mixin background-pos($values...) {
  @each $var in $values {
    background-position: #{$var};
  }
}

//FLEX FOR ALL BROWSERS

@mixin flex-basis-crossBrowser($value) {
  -ms-flex-preferred-size: $value;
  flex-basis: $value;
}

@mixin flex-grow-crossBrowser($value) {
  -webkit-box-flex: $value;
  -ms-flex-positive: $value;
  flex-grow: $value;
}

@mixin flex-justify-crossBrowser($value) {
  -webkit-box-pack: $value;
  -ms-flex-pack: $value;
  justify-content: $value;
}

@mixin flex-align-content-crossBrowser($value) {
  -ms-flex-line-pack: $value;
  align-content: $value;
}

@mixin flex-justify-self-crossBrowser($value) {
  -ms-grid-column-align: $value;
  justify-self: $value;
}

//FONT-WEIGHT mixins

@mixin font-w($val:200) {
  font-weight: $val;
}

// ANIMATIONS

@mixin animate-crossBrowser($values...) {
  @each $val in $values {
    -webkit-animation: #{$val};
    animation: #{$val};
  }
}


//INPUT FIELD MIXINS
@mixin removeDefaultNumberSpinners() {

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
}

//backdrop-filter glass effect

@mixin glassmorphismBackground($blurValue:2px) {
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur($blurValue);
  -webkit-backdrop-filter: blur($blurValue);
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius=$blurValue);
}


//CROSS BROWSER TRANSITION

@mixin customTransition ($values...) {
  @each $val in $values {
    -webkit-transition: #{$val};
    -o-transition: #{$val};
    transition: #{$val};
  }
}

//material-border
  @mixin mat-field-border(){
    border-radius:0;
    border:none;
    border-bottom: $material-border;
  }

  //mat-label
  @mixin mat-label(){
    position: absolute;
    left: 15px;
    top: 12px;
    transition: all 0.3s;
  }

  //roundArrowBase
  @mixin round-arrow-base(){
    width:40px;
    height:40px;
    border-radius: 50%;
    border: 1px solid #000;
    background:#fff;
  }

  //allicon
  @mixin allicon($valuesx, $valuey) {
    width:40px;
    height: 40px;
    background:$icon-sprite-img;
    background-position: $valuesx $valuey ;
  }
