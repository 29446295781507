@import "../../assets/scss/common.scss";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

#overlay {
    background: $white;
    color: $gray-600;
    position: fixed;
    height: $height-100;
    width: $width-100;
    z-index: $loader-z-index;
    top: 0;
    left: 0;
    float: left;
    text-align: center;
    padding-top: $loader-padding-top;
    opacity: $loader-background-opacity;
}

#component-loader {
    width: $width-100;
    float: left;
    text-align: center;
}

.spinner {
    margin: 0 auto;
    height: $spinner-size;
    width: $spinner-size;
    @include animate-crossBrowser(rotate 0.8s infinite linear);
    border: $spinner-theme;
    border-right-color: transparent;
    border-radius: 50%;
}

@-webkit-keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}